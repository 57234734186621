<template>
  <div
    ref="parent"
    class="map-modal d-flex flex-column white--bg"
    :class="{ loading: loading }"
  >
    <div
      v-if="permission"
      class="map-modal__permission d-flex flex-column align-center"
    >
      <p class="title-5 text-center">
        Предоставьте разрешение на получение вашего местоположения
      </p>
    </div>
    <div class="map-modal__header d-flex align-center justify-between">
      <d-btn
        data-test="back-button"
        icon
        color="custom"
        class="modal__back"
        @click="$emit('close')"
      >
        <img
          loading="lazy"
          src="@/assets/images/svg/arrow-right.svg"
          alt="arrow"
          style="transform: rotate(180deg)"
        >
      </d-btn>
      <button
        class="map-modal__city relative"
        @click="showDialogCity"
      >
        <span class="d-flex map-modal__city-icon" />
        {{ currentCity?.name }}
      </button>
    </div>

    <div class="map-modal__address d-flex relative">
      <LazyAddressCheckSearchAddressCheck
        ref="searchInputRef"
        v-model:street-props="street"
        :add-layer="true"
        class="flex-grow"
        @change-address="changeInputAddress"
      />
      <d-btn
        color="primary"
        :disabled="!tempHouseUrl"
        @click="changeAddress"
      >
        {{
          $route.path.includes("/complete/") ? "Отправить" : "Найти провайдеров"
        }}
      </d-btn>
    </div>
    <div class="map-modal__err relative">
      <p v-if="errMessage">
        {{ errMessage }}
      </p>
    </div>

    <div class="map-modal__map flex-grow">
      <YandexMap
        ref="myMap"
        v-model="map"
        class="map-modal__map-wrap"
        :detailed-controls="detailedControls"
        :controls="controls"
        :settings="{
          location: {
            center: coordinatesMap,
            zoom: tempHouseUrl ? 17 : 12,
          },
          suppressMapOpenBlock: true,
          suppressObsoleteBrowserNotifier: true,
        }"
      >
        <YandexMapLayer :settings="{ type: 'buildings' }" />
        <YandexMapDefaultSchemeLayer />
        <YandexMapFeatureDataSource :settings="{ id: '1322' }" />
        <YandexMapDefaultFeaturesLayer :settings="{ zIndex: 2010 }" />
        <YandexMapListener :settings="{ onClick: setMarker }" />
        <YandexMapControls :settings="{ position: 'top right' }">
          <YandexMapZoomControl />
        </YandexMapControls>
        <YandexMapMarker
          :settings="{
            coordinates: coordinatesMap,
          }"
          :marker-id="13243"
        >
          <div class="map-modal__map-marker" />
        </YandexMapMarker>
      </YandexMap>
    </div>

    <LazyVDialog
      v-model="dialogCity"
      :fullscreen="!disabledPortal"
      :width="disabledPortal ? 'auto' : ''"
    >
      <LazyModalCityCheck
        @close="dialogCity = false"
        @changed-city="changedCity"
      />
    </LazyVDialog>
  </div>
</template>

<script lang="ts" setup>
import { YandexMap,
  YandexMapLayer,
  YandexMapDefaultSchemeLayer,
  YandexMapFeatureDataSource,
  YandexMapMarker,
  YandexMapDefaultFeaturesLayer,
  YandexMapListener,
  YandexMapControls,
  YandexMapZoomControl,
  VueYandexMaps } from 'vue-yandex-maps'
import { useGeolocation } from '@vueuse/core'
import { nextTick } from 'vue'
import { useCities } from '~/store/cites/cities.store'
import { useMainStore } from '~/store/main/main.store'
import { useTariffs } from '~/store/tariffs/tariffs.store'
import { recentSearchFn } from '~/composible/recentSearch'
import { getAddresLocation } from '~/composible/getAddresLoc'

const props = defineProps({
  redirect: {
    default: true,
  },
})
const emit = defineEmits(['close'])

const ctx = useNuxtApp()
const $router = useRouter()
const $route = useRoute()
const cityStore = useCities()
const mainStore = useMainStore()
const tariffsStore = useTariffs()
const $gtm = useGtm()
const parent = ref()
const map = shallowRef()
const searchInputRef = ref()
const myMap = ref()
const errMessage = ref('')
const currentCity = computed(() => cityStore.getCity)
const getCommonHeaders = computed(() => mainStore.getCommonHeaders)
const getAddressText = computed(() => tariffsStore.getAddressText)
const houseUrl = computed(() => tariffsStore.getHouseUrl)
const disabledPortal = computed(() => mainStore.disabledPortal)
const dialogCity = ref(false)
const loading = ref(false)
const permission = ref<boolean | undefined>(undefined)
const geoLoc = useGeolocation()
const { addRecentSearch } = recentSearchFn()

const street = ref('')
const tempHouseUrl = ref('')
const tempLoc = ref()

const showDialogCity = () => {
  dialogCity.value = true
}

const changedCity = () => {
  dialogCity.value = false
}

const promiseRes = async (fiasId) => {
  if (fiasId) {
    return await $fetch(`https://${mainStore.domain}/api/locations/address-objects/${fiasId}/ancestors/`, {
      method: 'GET',
      headers: {
        ...getCommonHeaders.value,
      },
      params: {
        include_self: true,
        morph: true,
      },
    })
  }
}

const sendGtm = (action, value) => {
  if ($gtm) {
    $gtm.trackEvent({
      category: '',
      event: 'trackEvent',
      action,
      value,
    })
  }
}

const changeInputAddress = async (val) => {
  tempLoc.value = val
  tempHouseUrl.value = val.autoGeneratedUrl
  if (val.lat && val.lon) {
    nextTick(() => {
      coordinatesMap.value = [+val.lon, +val.lat]
      loading.value = false
    })
  }
  else {
    const geocoder = (await $fetch(`https://geocode-maps.yandex.ru/1.x/?apikey=${import.meta.env.VITE_YANDEX_KEY}&lang=ru_RU&geocode=${street.value.replace(/\s/g, '+')}&format=json`, {}))?.response
    if (geocoder?.GeoObjectCollection?.featureMember?.length) {
      coordinatesMap.value = geocoder?.GeoObjectCollection?.featureMember?.[0]?.GeoObject?.Point?.pos?.split(' ')
    }
    loading.value = false
  }
}

const changeAddress = async () => {
  ctx.$event('gtmSendEvent', {
    event: 'trackEvent',
    category: 'address',
    action: 'map-confirmed',
    label: '',
  })
  if (tempLoc.value) {
    if (!$route.path.includes('/complete/') && props.redirect) {
      mainStore.$patch({
        showLoader: true,
      })
    }
    let res
    const { getAddresLoc } = getAddresLocation()

    const promiseLoc = async () => {
      await getAddresLoc({
        url: tempLoc.value?.autoGeneratedUrl,
      })
    }
    const newPromiseRes = async () => {
      res = await promiseRes(
        tempLoc.value.fiasPath[tempLoc.value.fiasPath.length - 1],
      )
    }

    await Promise.all([promiseLoc(), newPromiseRes()])

    let selectedStreet
    let selectedCity = res?.address_objects?.find(
      (el: any) => el.level === 'city',
    )
    if (!selectedCity)
      selectedCity = res?.address_objects?.find(
        (el: any) => el.level === 'street',
      )
    else
      selectedStreet = res?.address_objects?.find(
        (el: any) => el.level === 'street',
      )
    cityStore.$patch({
      fullDataStreet: selectedStreet,
    })

    tariffsStore.$patch({
      addressAncestors: res,
    })

    const house = {
      autogenerated_url: tempLoc.value?.autoGeneratedUrl,
      id: tempLoc.value?.houseId,
      number: tempLoc.value?.houseNumber,
      dadata: {
        parent_fias_id: tempLoc.value.fiasPath[tempLoc.value.fiasPath.length - 1],
      },
    }

    addRecentSearch({
      name: street.value,
      houseUrl: tempLoc.value.autoGeneratedUrl,
      parents_tooltip:
        (selectedCity?.short_name ? selectedCity.short_name + ' ' : '')
        + selectedCity?.name,
      selectedHouse: house,
    })

    const coockieAddress = useCookie('currentAddress')

    coockieAddress.value = tempLoc.value.autoGeneratedUrl

    if (selectedCity) {
      cityStore.$patch({
        currentCity: selectedCity,
      })

      const coockieCity = useCookie('currentCity')
      coockieCity.value = selectedCity.fias_id
    }

    sendGtm('changeAddress', house)

    if (!$route.path.includes('/complete/') && props.redirect)
      $router.push({
        path: `/personal/address/${tempHouseUrl.value}/`,
      })
    emit('close')
  }
}
const setMarker = (e: any, a) => {
  const target = e?.entity?.geometry?.coordinates
  if (target) {
    coordinatesMap.value = target
    getAddress(coordinatesMap.value)
  }
  else if (a.coordinates?.length) {
    coordinatesMap.value = a.coordinates
    getAddress(coordinatesMap.value)
  }
}

const coordinatesMap = ref([37.617644, 55.755819])
const controls = ['zoomControl']
const detailedControls = { zoomControl: { position: { right: 10, top: 50 } } }

const coordsNA = async () => {
  const geocoder = (await $fetch(`https://geocode-maps.yandex.ru/1.x/?apikey=${import.meta.env.VITE_YANDEX_KEY}&lang=ru_RU&geocode=${coordinatesMap.value}&format=json`, {}))?.response
  if (geocoder?.GeoObjectCollection?.featureMember?.length) {
    street.value = geocoder?.GeoObjectCollection?.featureMember?.[0]?.GeoObject?.name
  }
  loading.value = false
}
const requestLocationPermission = async () => {
  coordinatesMap.value = [37.617644, 55.755819]
  if ('geolocation' in navigator) {
    const success = (event) => {
      permission.value = false
      if (
        event.coords.latitude
        && event.coords.longitude
        && event.coords.latitude !== 'infinity'
      ) {
        const coords = [event.coords.longitude, event.coords.latitude]
        coordinatesMap.value = coords
        getAddress(coordinatesMap.value)
      }
    }
    const error = async () => {
      permission.value = false
      errMessage.value = 'Геолокация не поддерживается'
      coordinatesMap.value = [37.617644, 55.755819]
    }
    navigator.geolocation.getCurrentPosition(success, error)
  }
  else {
    permission.value = false
    console.error('Геолокация не поддерживается')
    errMessage.value = 'Геолокация не поддерживается'
    const target = (await ymaps3.geolocation.getPosition())?.coords
    coordinatesMap.value = target || [37.617644, 55.755819]
  }
}

const client = ctx._apolloClients.default
const getAddress = async (val: number[]) => {
  loading.value = true
  let loc
  try {
    loc = (
      await client.query({
        query: gql`
          query getLocation($input: LocationInfoInput!) {
            location {
              reducedLocation(input: $input) {
                softNoindex
                fiasRegion
                hash
                autoGeneratedUrl
                addrObjFiasId
                houseFiasId
                streetFiasId
                fiasPath
                houseId
                addressName
                houseNumber
                houseType
                lat
                lon
                houseUrl
              }
            }
          }
        `,
        variables: {
          input: {
            lat: val[1],
            lon: val[0],
          },
          locationTermsInput2: {
            lat: val[1],
            lon: val[0],
          },
          providersInput2: {},
        },
        context: {
          headers: getCommonHeaders.value,
        },
        fetchPolicy: 'no-cache',
      })
    ).data.location.reducedLocation
  }
  catch (e: any) {
    loading.value = false
    return
  }

  if (loc.autoGeneratedUrl && !loc.houseNumber && loc.addressName) {
    street.value = loc.addressName + ' '
    searchInputRef.value?.searchAddress(loc.addressName)
    searchInputRef.value?.addressRef?.focus()
    searchInputRef.value?.setFocused(true)
    loading.value = false
    return
  }

  if (loc.fiasPath) {
    const res = await promiseRes(loc.fiasPath[loc.fiasPath.length - 1])

    const city = res?.address_objects.find((el: any) => el.level === 'city')
    const streetName = res?.address_objects.find(
      (el: any) => el.level === 'street',
    )
    if (city.fias_id !== currentCity.value.fias_id) {
      changeCity(city, res)
    }
    street.value
      = (streetName
        ? (streetName.short_name ? streetName.short_name + ' ' : '')
        + streetName.name
        + ' '
        : loc.addressName + ' ') + loc.houseNumber
    tempHouseUrl.value = loc.autoGeneratedUrl
    tempLoc.value = loc
    loading.value = false
  }
  else {
    tempHouseUrl.value = ''
    tempLoc.value = undefined
    coordsNA()
  }
  loading.value = false
}

const changeCity = (city: {}, res) => {
  $router.replace($route.path.replace(currentCity.value.url, city.url!))
  cityStore.$patch({
    currentCity: city,
  })
  const resCity = {
    house: undefined,
    address_objects: res.address_objects.filter(
      (el: any) => el.level === 'city',
    ),
  }
  tariffsStore.addressAncestors = resCity
  tariffsStore.house = {}
  tariffsStore.passThroughProviderId = null
  nextTick(() => cityStore.clearStreet())

  const coockieCity = useCookie('currentCity')
  const coockieAddress = useCookie('currentAddress')

  coockieCity.value = city.fias_id
  coockieAddress.value = ''
}
const scrollTopFn = () => {
  parent.value.scrollTo(0, 0)
  window.removeEventListener('resize', scrollTopFn)
}
const getCoordsForCity = async () => {
  loading.value = true
  const geocoder = (await $fetch(`https://geocode-maps.yandex.ru/1.x/?apikey=${import.meta.env.VITE_YANDEX_KEY}&lang=ru_RU&geocode=${getAddressText.value.cityOnly.replace(/\s/g, '+')}&format=json`, {}))?.response
  if (geocoder?.GeoObjectCollection?.featureMember?.length) {
    coordinatesMap.value = geocoder?.GeoObjectCollection?.featureMember?.[0]?.GeoObject?.Point?.pos?.split(' ')
  }
  loading.value = false
}
watch(VueYandexMaps.isLoaded, () => {
  if (VueYandexMaps.isLoaded) requestLocationPermission()
})
watch(() => currentCity.value?.fias_id,
  () => {
    getCoordsForCity()
  })
onMounted(async () => {
  parent.value.addEventListener('gesturestart', function (e) {
    e.preventDefault()
  })
  if (!geoLoc.error.value && geoLoc.coords.value.latitude === Number.POSITIVE_INFINITY) {
    permission.value = true
  }
  if (VueYandexMaps.isLoaded) {
    requestLocationPermission()
  }
  nextTick(() => parent.value.scrollTo(0, 0))
  if (ctx.$device.isAndroid) {
    window.addEventListener('resize', scrollTopFn)
  }
})

onBeforeUnmount(async () => {
  if (ctx.$device.isAndroid) {
    window.removeEventListener('resize', scrollTopFn)
  }
})
</script>

<style scoped lang="scss">
.map-modal {
  width: 780px;
  max-width: 100%;
  height: 560px;
  max-height: 100vh;
  padding: 16px 40px 40px;
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  touch-action: manipulation;
  -ms-touch-action: manipulation;

  @media (max-width: getBreakpoint(tablet)) {
    border-radius: 0;
    padding: 12px 16px 0;
    height: 100vh;
  }

  &.loading {
    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 10;
      background-color: rgba(color(white), 0.3);
    }
  }

  &__wrap {
    max-height: 100%;
  }

  &__map {
    @media (max-width: getBreakpoint(tablet)) {
      margin-left: -16px;
      margin-right: -16px;
    }

    &-wrap {
      width: 100%;
      height: 100%;
    }
    &-marker {
      position: relative;
      width: 32px;
      height: 32px;
      top: -32px;
      left: -16px;
      background: url("@/assets/images/svg/pin_large.svg")  50% 50% no-repeat;
      background-size: contain;
    }
  }
  &__err {
    p {
      top: 10px;
      left: 12px;
      position: absolute;
      z-index: 1;
    }
  }

  &__permission {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    background-color: rgba(color(white), 0.7);
    padding-top: 224px;
    img {
      width: 350px;
      height: 400px;
    }
    @media (max-width: getBreakpoint(tablet)) {
      padding-bottom: 0;
      justify-content: center;
    }
  }

  &__address {
    z-index: 11;
    margin-bottom: 24px;
    @media (max-width: getBreakpoint(tablet)) {
      flex-direction: column;
    }
    .d-btn {
      margin-left: 12px;
      @media (max-width: getBreakpoint(tablet)) {
        margin-left: 0;
        height: 44px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        height: 42px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        height: 40px;
      }
    }
    &:deep(.sa-check__check-textarea),
    &:deep(.sa-check__check),
    &:deep(.sa-check__loading),
    &:deep(.sa-check__check-popper) {
      background-color: color(gray-p);
    }
  }

  &__header {
    margin-bottom: 16px;
    position: relative;
    z-index: 11;
  }
  &__back {
    padding: 0;
    width: 32px;
    height: 32px;
    @media (max-width: getBreakpoint(mobile-lg)) {
      width: 28px;
      height: 28px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      width: 24px;
      height: 24px;
    }
    &:deep(svg) {
      width: 32px;
      height: 32px;
      @media (max-width: getBreakpoint(mobile-lg)) {
        width: 28px;
        height: 28px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        width: 24px;
        height: 24px;
      }
    }
  }
  &__city {
    margin-left: 28px;
    color: color(secondary-dark);
    transition: color 0.3s;

    &-icon {
      position: absolute;
      left: -28px;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      mask-image: url('@/assets/images/svg/location.svg');
      background-color: currentColor;
      mask-position: center;
      mask-repeat: no-repeat;
      mask-size: 24px;
    }

    &:hover {
      color: color(primary);
    }
  }
}
</style>
